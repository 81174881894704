const translation_fr =

{
    "COMING_SOON":{
        "TITLE_H1": "Nous arrivons bientôt",
        "TITLE_H2": "Notre site web est présentement en construction",
        "TEXT1": "Vous pouvez nous suivre sur les réseaux sociaux pour rester informé de nos dernières nouvelles et événements, ou nous contacter directement pour toute question ou demande de renseignements.",
        "BTN1": "Nous joindre"
    }
}

export default translation_fr;
