import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import firebase from "./config/firebase";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

const app = createApp(App);

// Optionally, you can add Firebase to Vue's global properties
app.config.globalProperties.$firebase = firebase;

import {
    faPhone
  } from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookSquare,
  faSquareXTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

library.add(faSquareXTwitter, faFacebookSquare, faLinkedin, faPhone);

app.use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
