import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBKx9Ev_iN53SQ8J3-zvbr41EvAYBtZJ1A",
    authDomain: "aqua-berge.firebaseapp.com",
    projectId: "aqua-berge",
    storageBucket: "aqua-berge.appspot.com",
    messagingSenderId: "16262926887",
    appId: "1:16262926887:web:32113b1ce7d14139563402",
    measurementId: "G-HKV461CKFV"
  };

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase;